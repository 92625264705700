var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-button',{style:({ marginBottom: '15px' }),attrs:{"type":"primary"},on:{"click":function($event){_vm.$router.push({ name: 'users-new' }).catch(function (_err) {})}}},[_vm._v(" Создать нового сотрудника ")]),_c('a-table',{attrs:{"bordered":"","columns":_vm.columns,"loading":_vm.loading,"row-key":function (record) { return record.id; },"pagination":{ pageNumber: _vm.pageNumber, total: _vm.usersTotal },"data-source":_vm.users},on:{"change":_vm.handleTableChange},scopedSlots:_vm._u([{key:"actions",fn:function(record){return _c('span',{style:({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })},[_c('a-button',{attrs:{"icon":"edit","title":"Редактировать","type":"primary"},on:{"click":function($event){_vm.$router
            .push({
              name: 'users-edit',
              params: { id: record.id },
            })
            .catch(function (_err) {})}}}),_c('a-popconfirm',{attrs:{"ok-text":"Да","cancel-text":"Нет","placement":"topRight","title":"Вы уверены что хотите удалить этого сотрудника?"},on:{"confirm":function($event){return _vm.deleteUser(record)}}},[_c('a-button',{style:({ marginLeft: '15px' }),attrs:{"type":"danger","title":"Удалить","icon":"delete"}})],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }