<template>
  <div>
    <a-button
      type="primary"
      :style="{ marginBottom: '15px' }"
      @click="$router.push({ name: 'users-new' }).catch(_err => {})"
    >
      Создать нового сотрудника
    </a-button>

    <a-table
      bordered
      :columns="columns"
      :loading="loading"
      :row-key="record => record.id"
      :pagination="{ pageNumber, total: usersTotal }"
      :data-source="users"
      @change="handleTableChange"
    >
      <span
        slot="actions"
        slot-scope="record"
        :style="{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }"
      >
        <a-button
          icon="edit"
          title="Редактировать"
          type="primary"
          @click="
            $router
              .push({
                name: 'users-edit',
                params: { id: record.id },
              })
              .catch(_err => {})
          "
        />
        <a-popconfirm
          ok-text="Да"
          cancel-text="Нет"
          placement="topRight"
          title="Вы уверены что хотите удалить этого сотрудника?"
          @confirm="deleteUser(record)"
        >
          <a-button
            :style="{ marginLeft: '15px' }"
            type="danger"
            title="Удалить"
            icon="delete"
          />
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import { DELETE_USER, GET_USERS, USERS, USERS_TOTAL } from '@/store/types'

const columns = [
  {
    title: 'Имя сотрудника',
    dataIndex: 'username',
  },
  {
    title: 'Имя',
    dataIndex: 'first_name',
  },
  {
    title: 'Фамилия',
    dataIndex: 'last_name',
  },
  {
    title: 'Роли',
    ellipsis: true,
    customRender: (_text, user) =>
      user.roles && user.roles.map(role => role.name).join(', '),
  },
  {
    title: 'Создан',
    customRender: (_text, user) =>
      new Date(user.created_at).toLocaleString('ru-RU'),
  },
  {
    title: 'Действия',
    key: 'actions',
    width: 150,
    scopedSlots: { customRender: 'actions' },
  },
]

export default {
  data() {
    return {
      data: [],
      columns,
      loading: false,
      pageNumber: 1,
    }
  },

  computed: {
    users() {
      return this.$store.getters[USERS]
    },
    usersTotal() {
      return this.$store.getters[USERS_TOTAL]
    },
  },

  created() {
    this.getUsers()
  },

  methods: {
    handleTableChange(pagination) {
      this.pageNumber = pagination.current

      this.getUsers()
    },
    getUsers() {
      this.$store.dispatch(GET_USERS, { pageNumber: this.pageNumber })
    },
    deleteUser(user) {
      this.$store.dispatch(DELETE_USER, user)
    },
  },
}
</script>
